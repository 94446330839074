import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import { container, knight, prize, title } from './closed.module.scss';
import { IPage } from '../../../models/page.model';

import AkcjaGorzkaLayout from '../../../layouts/akcja-gorzka-layout';
import AkcjaGorzkaPrize from '../../../components/organisms/akcja-gorzka-prize';

interface IAkcjaGorzkaClosedPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const knightImg = '../../../assets/images/akcja-gorzka/knight.png';

const Closed: React.FC<IAkcjaGorzkaClosedPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;

    return (
        <AkcjaGorzkaLayout
            page={page}
            className={container}
            headerProps={{
                titleFirstLine: t('akcja.gorzka.closed.title.1'),
                titleSecondLine: t('akcja.gorzka.closed.title.2'),
            }}
            showSideImages={true}
        >
            <p className={title}>{t('akcja.gorzka.title')}</p>
            <AkcjaGorzkaPrize className={prize} />
            <StaticImage src={knightImg} alt={''} className={knight} />
        </AkcjaGorzkaLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Closed;
