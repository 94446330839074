import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import { container, info, prizes, prizesMobile, title } from './akcja-gorzka-prize.module.scss';

const prizesImg = '../../assets/images/akcja-gorzka/prizes.png';
const prizesMobileImg = '../../assets/images/akcja-gorzka/prizes-mobile.png';
const titleImg = '../../assets/images/akcja-gorzka/title.png';

interface IAkcjaGorzkaPrizeProps {
    className?: string;
    showTitle?: boolean;
}

const AkcjaGorzkaPrize: React.FC<IAkcjaGorzkaPrizeProps> = ({
    className = '',
    showTitle = false,
}) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${className}`}>
            <StaticImage src={prizesImg} alt={''} className={prizes} />
            <StaticImage src={prizesMobileImg} alt={''} className={prizesMobile} />
            <p className={info}>
                {t('akcja.gorzka.prize.info.1')}
                <strong>{t('akcja.gorzka.prize.info.2')}</strong>
                {t('akcja.gorzka.prize.info.3')}
                <strong>{t('akcja.gorzka.prize.info.4')}</strong>
                {t('akcja.gorzka.prize.info.5')}
                <br />
                <strong>{t('akcja.gorzka.prize.info.6')}</strong>
            </p>
            {showTitle && <StaticImage src={titleImg} alt={''} className={title} />}
        </div>
    );
};

export default AkcjaGorzkaPrize;
